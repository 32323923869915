 /* 
 Hulp App CSS V1.0 
 Built By Aslan  
 ***************** 
 */


 /* Font Family */
 @font-face {
     font-family: 'Matter SQ';
     font-style: normal;
     font-weight: 300;
     src: url("./assets/fonts/MatterSQ-Light.woff") format('woff'),
 }

 @font-face {
     font-family: 'Matter SQ';
     font-style: normal;
     font-weight: 400;
     src: url("./assets/fonts/MatterSQ-Regular.woff") format('woff')
 }

 @font-face {
     font-family: 'Matter SQ';
     font-style: normal;
     font-weight: 500;
     src: url("./assets/fonts/MatterSQ-Medium.woff") format('woff')
 }

 @font-face {
     font-family: 'Matter SQ';
     font-style: normal;
     font-weight: 600;
     src: url("./assets/fonts/MatterSQ-SemiBold.woff") format('woff')
 }

 @font-face {
     font-family: 'Matter SQ';
     font-style: normal;
     font-weight: 700;
     src: url("./assets/fonts/MatterSQ-Bold.woff") format('woff')
 }

 /* Body */
 html {
     font-family: "Matter SQ", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     box-sizing: border-box;
     font-style: normal;
     font-weight: 400;
     margin: 0 auto;
     color: #004453 !important;
     font-size: 16px;
 }

 *,
 :after,
 :before {
     margin: 0;
     padding: 0;
     box-sizing: inherit;
     font-style: inherit;
     font-weight: inherit;
     font-family: inherit !important;
     color: inherit;
     line-height: 1.5
 }

 :root {
     --grey-shade: #D9D9D9;
     --midnight-green: #004453;
     --robin-egg-blue: #17D4BE;
     --light-grey-shade: #F6F6F6;
     --grey-dashboard-bg: #EEEEEE;
     --white: #FFFFFF;
     --black: #222222;
     --amber: #D49417;
     --error: #D44417;

 }

 .uk-padding {
     padding: 24px;
 }

 .uk-margin {
     margin-bottom: 24px;
 }



 /* Nav */

 .dashboard {
     background-color: var(--grey-dashboard-bg);
 }

 .dashboard-logo {
     /* width: 80px; */
     padding-top: 20px;
 }

 .nav-vertical {
     background-color: var(--white);
     min-width: 280px;
     height: 100vh;
     position: -webkit-sticky;
     position: sticky;
     top: 0;
 }

 .dashboard-nav-wrapper {
     background-color: var(--grey-dashboard-bg);
     position: -webkit-sticky;
     position: sticky;
     top: 0;
 }

 .dashboard-nav {
     background-color: var(--white);
     padding: 20px 40px;
     border-radius: 16px;
 }

 .dashboard-nav-spacer {
     height: 24px;
     background-color: var(--grey-dashboard-bg);
 }

 .dashboard-body {
     background-color: var(--white);
     padding: 40px;
     border-radius: 16px;
     min-height: 82vh;
 }

 .dashboard-body-container {
     /* overflow: scroll; */
 }

 .side-nav li a {
     font-size: 18px;
     font-style: normal;
     font-weight: 500;
     line-height: 26px;
     padding: 0 0 24px 0;
     color: var(--midnight-green);
 }

 .side-nav li a:hover {
     color: var(--robin-egg-blue);
 }

 .side-nav .active a {
     color: var(--robin-egg-blue);

 }

 /* Input */

 .uk-hulp-input {
     height: 60px;
     font-size: 1.125rem;
     border-radius: 8px;
     border: 1px solid var(--grey-shade);
     color: var(--midnight-green);
     font-weight: 500;
     line-height: 1.625rem;
     padding: 0 16px;
 }

 .uk-hulp-textarea {
     font-size: 1.125rem;
     border-radius: 8px;
     border: 1px solid var(--grey-shade);
     color: var(--midnight-green);
     font-weight: 500;
     line-height: 1.625rem;
     padding: 16px;
     /* min-height: 100px; */
 }

 .uk-hulp-upload {
     text-align: center;
     font-size: 0.85rem;
     border-radius: 8px;
     border: 2px dashed var(--grey-shade);
     color: var(--midnight-green);
     font-weight: 500;
     line-height: 1.625rem;
     padding: 32px 16px;
     min-height: 110px;
 }

 .uk-upload-btn {
     background-color: transparent;
     border: none;
     text-decoration: underline;
     color: var(--robin-egg-blue);
 }

 .uk-hulp-label {
     font-size: 1rem;
     line-height: 1.5rem;
     color: var(--midnight-green);
 }

 .uk-input-password {
     border-style: dotted;
 }

 .uk-hulp-input:focus {
     border: 2px solid rgba(0, 68, 83, 0.50);
     color: var(--midnight-green);
 }

 .uk-hulp-textarea:focus {
     border: 2px solid rgba(0, 68, 83, 0.50);
     color: var(--midnight-green);
 }


 .uk-hulp-button {
     margin-top: 20px;
     text-transform: none;
     border-radius: 16px;
     background: var(--midnight-green);
     color: #ffffff;
     font-size: 1.125rem;
     font-weight: 600;
     line-height: 1.625rem;
     padding: 20px 40px;
 }

 .uk-hulp-button:hover {
     color: var(--robin-egg-blue);
 }

 .hulp-form {
     margin-top: 40px;
 }

 .password-input {
     position: relative;
 }

 .show-btn {
     position: absolute;
     right: 16px;
     top: 65%;
     -webkit-transform: translateY(-50%);
     transform: translateY(-50%);
     cursor: pointer;
     z-index: 8999;
     background-color: #ffffff;
     padding: 16px 0px 16px 16px;
 }

 .account-type-btn {
     border-radius: 16px;
     border: 1px solid #D9D9D9;
     padding: 16px 20px;
 }

 .account-type-btn:hover {
     border: 2px solid var(--midnight-green);
     background: rgba(23, 212, 190, 0.05);
     stroke: #17D4BE;
 }

 .account-selected-btn {
     border-radius: 8px;
     border: 1px solid #D9D9D9;
     padding: 16px 20px;
 }

 .account-selected-btn:hover {
     border: 2px solid var(--midnight-green);
     background: rgba(23, 212, 190, 0.05);
     stroke: #17D4BE;
 }


 .account-type-btn:hover .icon-svg path {
     stroke: var(--robin-egg-blue) !important;
 }

 .account-type-btn:hover .chevron-svg path {
     stroke: var(--midnight-green) !important;
 }

 .account-type-link {
     text-decoration: none !important;
     color: var(--midnight-green);
 }

 .account-type-link:hover {
     text-decoration: none !important;
     color: var(--midnight-green);
 }

 a {
     color: var(--robin-egg-blue);
 }

 .uk-hulp-checkbox {
     color: var(--midnight-green);
 }

 .uk-hulp-checkbox:checked {
     background-color: var(--robin-egg-blue);
 }




 /* Header */


 .header-3 {
     color: var(--midnight-green);
     font-size: 2.5rem;
     font-weight: 500;
     line-height: 3.25rem;
 }

 .header-5 {
     color: var(--midnight-green);
     font-size: 1.5rem;
     font-weight: 500;
     line-height: 2rem;
 }

 .header-6 {
     color: var(--midnight-green);
     font-size: 1.25rem;
     font-weight: 500;
     line-height: 1.625rem;
 }

 .header-emoji {
     padding-right: 8px;
 }

 /* Paragraph */

 .body {
     font-size: 1rem;
     line-height: 1.5rem;
 }

 .body-medium {
     font-size: 1rem;
     font-weight: 500;
     line-height: 1.5rem;
 }

 p {
     font-size: 1.125rem;
     font-weight: 400;
     line-height: 1.625rem;
 }

 .p-bold {
     font-weight: 500;
 }

 .meta-text {
     color: var(--midnight-green);
     font-size: 0.75rem;
     font-style: normal;
     font-weight: 400;
     line-height: 1rem;
 }

 .meta-text-medium {
     color: var(--midnight-green);
     font-size: 0.75rem;
     font-weight: 500;
     line-height: 1rem;
 }

 .meta-text-semibold {
     color: var(--midnight-green);
     font-size: 0.75rem;
     font-weight: 600;
     line-height: 1rem;
 }



 /* Loader */

 #loader {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100vw;
     height: 100vh;
     text-align: center;
     position: fixed;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     background-color: #fff;
     z-index: 9999;
 }

 #loader>svg {
     display: inline-block;
     fill: none;
     stroke: #17D4BE;
     stroke-width: 3;
     flex: 0 0 auto;
     pointer-events: none;
     user-select: none;
     vertical-align: middle;
     animation: spin 1s linear infinite, fade .2s ease-in-out !important;
 }

 #btnloader>svg {
     display: inline-block;
     fill: none;
     stroke: #fff;
     stroke-width: 3;
     flex: 0 0 auto;
     pointer-events: none;
     user-select: none;
     vertical-align: middle;
     animation: spin 1s linear infinite, fade .2s ease-in-out;
 }

 @keyframes spin {
     0% {
         transform: rotate(0);
     }

     100% {
         transform: rotate(360deg);
     }
 }

 /* Colors */
 .grey-shade {
     color: var(--grey-shade);
 }

 .midnight-green {
     color: var(--midnight-green);
 }

 .robin-egg-blue {
     color: var(--robin-egg-blue);
 }

 .white {
     color: var(--white);
 }


 /* Links */
 .reset-link:hover {
     color: var(--midnight-green);
     text-decoration: none;
 }



 /* alerts */
 .uk-hulp-alert {
     background-color: var(--error);
     padding: 8px 16px;

 }

 .uk-hulp-alert p {
     color: var(--light-grey-shade);
     font-size: 1rem;
     font-weight: 500;
     /* line-height: 1rem; */
 }


 /* Dashboard */
 .name-block {
     /* padding: 8px 24px; */
 }

 .profile-text {
     background-color: var(--midnight-green);
     color: var(--white);
     padding: 8px 10px;
     border-radius: 24px;
 }

 .verification-pending-pill {
     color: var(--white);
     border-radius: 16px;
     background: var(--amber);
     padding: 12px 16px;

 }

 /* .verification-pending-bar {
     background: var(--amber);
     padding: 8px;
 } */

 .verification-pending-bar {
     background: var(--amber);
     position: sticky;
     position: -webkit-sticky;
     /* For Safari */
     top: 0;
     padding: 8px;
     z-index: 10
 }

 .account-type-pill {
     background-color: var(--light-grey-shade);
     padding: 12px 16px;
     border-radius: 15px;
 }

 .uk-progress {
     height: 4px;
     background-color: var(--grey-shade);
 }

 .uk-progress::-webkit-progress-value {
     background-color: var(--robin-egg-blue) !important;
 }

 .profile-toggle:hover{
    color: var(--robin-egg-blue);
    text-decoration: none;
 }


 /* Media Queries */
 /* Tablet */
 @media screen and (max-width: 768px) {
     p {
         font-size: 1rem;
         font-weight: 400;
         line-height: 1.5rem;
     }

     .header-3 {
         font-size: 2rem;
         line-height: 2.5rem;
     }

     .header-5-mobile {
         font-size: 1.25rem;
         line-height: 2rem;
         margin-top: 20px;
     }
 }

 /* Mobile */
 @media screen and (max-width: 480px) {}